body {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 14px;
}

* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

p{
    line-height: 26px;
}

.page-header {
    background-color: #361041;
    width: 100%;
    padding: 50px 0;
    height: 290px;
}

.page-header h1 {
    width: 50%;
    font-size: 20px;
    font-weight: normal;
    color: #fff;
    line-height: 35px;
    position: relative;
    top: 30px;
}

.page-header h1 strong {
    margin-bottom: 50px;
    display: inline-block;
}

.page-header .layout-width {
    display: flex;
    justify-content: space-between;
}

.page-header .layout-width img {
    width: 50%;
}

.page-header h1>span {
    color: #FFF;
    font-weight: bold;
}

.phone .fa {
    margin-right: 10px;
}