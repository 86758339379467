@import url('./utilies.css');

/*container*/
.container {
  background-color: #FFFFFF;
  width: 100%;
  margin: 0 auto;
  margin-top: 38px;
}

.layout-width {
  width: 90%;
  margin: 0 auto;
}

.layout-width h2 {
  font-size: 30px;
}

/*header-------------------------------------------------*/
header {
  background-color: #361041;
  padding: 20px;
  color: #fff;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  z-index: 500;
  top: 0;
}

header.pink {
  background-color: #ed174a;
}

header.pink a {
  color: #f5d8df;
}

header ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: end;
}

header ul li a {
  color: #b79cbe;
  padding: 10px;
  margin-right: 10px;
}

header ul li a::before {
  content: "\f0c4";
  font-family: "FontAwesome";
  margin-right: 5px;
}

header ul li a:hover,
header ul li a.active {
  color: #FFF;
  transition: .4s;
}

header ul li a.active {
  font-weight: bold;
}

header .phone {
  float: left;
}

header.pink .sai-boutique-small {
  display: block;
}

header .sai-boutique-small {
  display: none;
  float: left;
  position: relative;
  top: 2px;
}

header .sai-boutique-small img {
  width: 80%;
}

header .icon-hamburger {
  display: none;
  cursor: pointer;
}

/*banner-------------------------------------------------*/
.banner {
  /* background: #ed174a url('../images/banner_bg2.jpg') no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  position:relative; */
  width: 100%;
}

.banner img {
  width: 100%;
  position: relative;
  top: 0;
}

.banner .banner-heading {
  color: #fff;
  position: absolute;
  top: 350px;
  font-weight: normal;
  line-height: 25px;
  width: 35%;
  right: 400px;
  text-align: right;
  z-index: 100;
  font-size: 20px;
}

.banner .banner-heading::before,
.banner .banner-heading::after {
  content: '"';
  font-size: 30px;
}

.banner-container {
  width: 90%;
  margin: 0 auto;
}


/*logo-------------------------------------------------*/
.logo {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 130px;
  top: 150px;
  z-index: 30;
}

.logo .logo-model {
  width: 100px;
  height: 200px;
  margin: 0 auto;
}

.logo .logo-saiboutique {
  width: 230px;
  height: auto;
  margin: 0 auto;
  position: relative;
  top: -40px;
  left: 20px;
}

/*footer-------------------------------------------------*/
footer {
  border-top: 1px solid #adadad;
  background-color: #e1e1e1;
  padding: 60px;
  display: flex;
  justify-content: space-between;
  color: #333;
}

footer address {
  text-align: left;
  font-style: normal;
}

footer .kc-logo {
  float: right;
  text-align: right;
  position: relative;
}
footer .kc-logo span{
  display:none;
}
footer .kc-logo a {
  display: block;
  width: 30px;
  text-align: right;
  float: right;
  margin: 0px 0 0 0;
}
footer .kc-logo a img{
  width:100%;
}

footer .kc-logo .copyright {
  display: none;
}

/*home banner-------------------------------------------------*/
.hero {
  width: 400px;
  position: relative;
  float: right;
  bottom: -55px;
}

.hero img {
  width: 100%;
}

.circle {
  background: url('../images/circle.svg') no-repeat;
  width: 200px;
  height: 200px;
  z-index: 1;
  position: absolute;
  background-size: contain;
  top: 90px;
  left: 170px;
  opacity: 0.5;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*collections-------------------------------------------------*/
.collections {
  background-color: #ffcfd5;
  width: 100%;
}

.collections .collections-images {
  display: flex;
  flex-direction: row;
}

.collections .collections-images a {
  width: 25%;
  border-right: 1px solid #ab7b80;
  display: flex;
  transition: .4s;
  position: relative;
  justify-content: center;
}

.collections .collections-images a:hover img {
  opacity: 1;
  transition: .4s;
}

.collections .collections-images img {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transition: .4s;
}

.collections .collections-images img:last-child {
  border: 0;
}

.collections .layout-width {
  width: 100%;
}

.collections .collections-images a:hover .collections-name {
  opacity: 1;
  transition: .4s;
}

.collections .collections-images:hover a:not(:hover) {
  filter: blur(3px);
}

.collections .collections-name {
  background-color: #ff003c;
  border-radius: 140px;
  width: 140px;
  height: 140px;
  color: #fff;
  position: absolute;
  text-align: center;
  bottom: 30px;
  box-shadow: 0 0 20px 0 #000;
  opacity: 0;
  transition: .4s;
  z-index: 1;
}

.collections .collections-name p {
  position: relative;
  top: 40%;
  line-height: normal;
  width: 60%;
  margin: 0 auto;
}

/*testimonials-------------------------------------------------*/
.testimonials {
  background-color: #361041;
  width: 100%;
  color: #FFFFFF;

}

.testimonials .layout-width {
  padding: 50px;
}

.testimonials h2::after {
  content: "\f118";
  font-family: "FontAwesome";
  margin-left: 15px;
}

.testimonials img {
  border-radius: 150px;
  width: 150px;
  height: 150px;
  border: 3px solid #fff;
}

.testimonials .testimonials-cards {
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-around;
}

.testimonials-cards figure {
  display: flex;
  flex-direction: row;
  width: 40%;
}

.testimonials-cards figure .testimonials-card-text {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}


/*about-------------------------------------------------*/
.about .page-header {
  background: #361041 url('../images/bhuvana.jpg') no-repeat;
  width: 100%;
  background-position: 85% 0;
}

/*highlights-------------------------------------------------*/
.highlights {
  background-color: #FFCE00;
  padding: 0px 0;
  position: relative;
}

.highlights h2 {
  font-weight: normal;
  line-height: 35px;
  font-size: 24px;
  top: 100px;
  position: relative;
}

.highlights .layout-width {
  display: flex;
  padding: 100px 0;
  gap: 30px;
}

.highlights img {
  width: 40%;
}

.highlights.services-items-wrapper .layout-width {
  flex-direction: column;
  top: 30px;
  position: relative;
}

.highlights.services-items-wrapper .layout-width h2 {
  top: 0px;
  font-weight: bold;
}

.highlights .tape {
  background: url('../images/tape2.svg') no-repeat;
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
}

/*services-------------------------------------------------*/
.services .page-header {
  background: #361041 url('../images/services_header.png') no-repeat;
  width: 100%;
  background-position: 85% 0;
}

.services .services-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 160px;
  column-count: 2;
  margin: 0;
  padding: 10px 0;
  font-size: 15px;
  width: 100%;
  list-style: none;
  gap: 10px;
}

.services .services-items li {
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
}

.services .services-items li::before {
  content: "\f0c4";
  font-family: "FontAwesome";
  margin-right: 15px;
}


/*contact-------------------------------------------------*/
.contact .page-header h1 .phone {
  margin: 30px 0;
}

.contact .page-header img {
  width: 30%;
}

/*gallery-------------------------------------------------*/
.gallery .page-header {
  background: #361041 url('../images/gallery_header.png') no-repeat;
  background-position: right 0;
}

.gallery .page-header h1 {
  width: 40%;
}

.gallery .gallery-items {
  column-count: 4;
  column-gap: 20px;
  padding: 30px;
}

.gallery .gallery-items figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 20px;
  break-inside: avoid;
}

.gallery .gallery-items figure>img {
  grid-row: 1 / -1;
  grid-column: 1;
}

.gallery figure img {
  max-width: 100%;
  display: block;
  border-radius: 16px;
}

.notfound .page-header{
  height:250px;
  background-color: white;
  text-align: center;
}
.notfound .page-header img{
  width:25%;
  margin:30px auto;
}

/*media queries-----------------------------------------------------*/
@media (min-width: 1920px) {
  .banner .banner-heading {
    right: 600px;
    top: 450px;
    width: 30%;
  }
}

@media (max-width: 1536px) {
  .banner .banner-heading {
    right: 450px;
  }
}

@media (max-width: 1440px) {
  .banner .banner-heading {
    right: 450px;
  }
}

@media (max-width: 1280px) {
  .banner .banner-heading {
    right: 400px;
  }
}

@media (max-width: 1080px) and (orientation:landscape) {
  .banner .banner-heading {
    display: none;
  }
}

@media (max-width: 1025px) and (orientation:portrait) {
  .banner .banner-heading {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .banner .banner-heading {
    display: none;
  }

  .logo .logo-model {
    height: 100px;
  }

  .logo {
    top: 100px;
  }

  .circle {
    top: 30px;
  }

  .logo .logo-saiboutique {
    top: 0;
    width: 200px;
  }

  .testimonials .layout-width {
    width: auto;
  }

  .testimonials .testimonials-cards {
    flex-direction: column;
    gap: 50px;
  }

  .testimonials-cards figure {
    width: 100%;
  }

  .testimonials img {
    width: 100px;
    height: 100px;
  }

  footer {
    flex-direction: row;
    padding: 30px;
    align-items: center;
  }

  footer img {
    width: 200px;
    margin: 0 auto;
  }

  footer address {
    text-align: left;
    margin: 0px 0;
  }

  footer .kc-logo {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: right;
  }

  footer .kc-logo a {
    width: 50px;
  }

  footer .kc-logo img {
    width: 100%;
  }

  footer address .copyright {
    display: block;
  }

  footer .kc-logo .copyright {
    display: none;
    margin: 20px 0 0 0;
  }

}

@media screen and (min-width:320px) and (max-width: 767px) {
  .banner .banner-heading {
    display: none;
  }

  .home-banner {
    display: none;
  }

  header {
    width: 100%;
    position: fixed;
    height: 30px;
    padding: 0px 0 20px 0;
    background: transparent;
    box-shadow: none;
  }

  header.pink {
    background: transparent;
  }

  header .layout-width {
    width: 100%;
  }

  header .icon-hamburger,
  .icon-hamburger {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: 0;
    color: #fff;
    z-index: 600;
    cursor: pointer;
  }

  header ul {
    flex-direction: column;
    padding: 20px;
    display: none;
    clear: both;
    width: 100%;
    position: relative;
    top: 20px;
  }

  header ul li a {
    padding: 10px 0;
    display: block;
  }

  header ul li a::before {
    padding-right: 15px;
  }

  .header-container.visible header.pink {
    height: auto;
    background-color: #ed174a;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.4);
  }

  .header-container.visible .icon-hamburger {
    transform: rotate(90deg)
  }

  .header-container.visible ul {
    display: block;
  }

  header.pink .sai-boutique-small {
    display: none;
    position: relative;
    margin: 15px 0 0 20px;
  }

  .banner {
    background: url('../images/hero_bg.jpg') no-repeat;
    background-size: cover;
    height: 100%;
  }

  .logo {
    position: relative;
    top: 0;
    left: 0;
  }

  .logo .logo-model {
    position: relative;
    left: -10px;
  }

  .logo .logo-saiboutique {
    left: 0;
  }

  .container {
    margin-top: 0;
  }

  .banner-container {
    width: 100%;
    height: auto;
  }

  .hero {
    display: none;
  }

  .circle {
    display: none;
  }

  .collections .collections-images {
    flex-direction: column;
  }

  .collections .collections-images a {
    width: 100%;
    border-bottom: 1px solid #ab7b80;
    border-right: 0;
  }

  .collections .collections-images a:last-child {
    border-bottom: 0;
  }

  .testimonials {
    padding: 40px;
    text-align: center;
    padding: 8%;
    width: auto;
  }

  .testimonials .layout-width {
    padding: 0;
    width: 100%;
  }

  .testimonials .testimonials-cards {
    flex-direction: column;
    margin: 25px 0;
  }

  .testimonials-cards figure {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .testimonials-cards figure .testimonials-card-text {
    margin: 15px 0;
  }

  .testimonials-cards figure .testimonials-card-text h4 {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .testimonials-cards figure .testimonials-card-text p {
    font-size: 18px;
  }

  footer {
    flex-direction: column;
    padding: 8%;
    align-items: center;
    text-align: center;
  }

  footer img {
    width: 60%;
  }

  footer address {
    text-align: center;
    margin: 25px 0
  }

  footer .kc-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  footer .kc-logo a {
    margin: 10px 0;
    width: 20%;
  }

  footer .kc-logo a img {
    width: 100%;
  }

  footer .copyright {
    display: none;
  }

  footer .kc-logo .copyright {
    display: block;
  }

  .highlights {
    padding: 4%;
    width: auto;
    text-align: center;
  }

  .highlights .layout-width {
    flex-direction: column;
    padding: 0;
  }

  .highlights .layout-width img {
    width: 100%;
    margin: 15px 0;
  }

  .highlights .tape {
    display: none;
  }

  .highlights h2 {
    top: 0;
  }

  .page-header {
    height: auto;
  }

  .about .page-header {
    height: 100vh;
    background-position: center 0;
  }

  .about .page-header h1 {
    width: 100%;
    top: 300px;
  }

  .about .highlights {
    padding: 8%;
  }

  .page-header .layout-width {
    top: 300px;
    text-align: center;
  }

  .page-header .layout-width h1 strong {
    margin: 15px 0;
  }

  .services .page-header {
    height: 100vh;
    background-size: auto;
    padding-bottom: 150px;
    background: #361041 url('../images/mobile_services.jpg') no-repeat;
  }

  .services .page-header h1 {
    top: 0;
    width: 100%;
  }

  .services .page-header .layout-width {
    position: relative;
    top: 350px;
  }

  .services .services-items {
    height: auto;
    margin-bottom: 25px;
    text-align: left;
  }

  .services .services-items li::before {
    margin-left: 10px;
  }

  .gallery .page-header {
    background-size: auto;
    height: 100vh;
    padding-bottom: 0;
    background: #361041 url('../images/mobile_gallery.jpg') no-repeat;
  }

  .gallery .page-header h1 {
    width: 100%;
  }

  .gallery .page-header .layout-width {
    position: relative;
    top: 350px;
  }

  .gallery .gallery-items {
    padding: 0;
    column-count: auto;
  }

  .gallery .gallery-items .layout-width {
    width: 100%;
  }

  .gallery .gallery-items figure>img {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .gallery .gallery-items figure {
    margin: 0;
  }

  .contact .page-header h1 {
    width: 100%;
  }

  .contact .page-header img {
    display: none;
  }
  .notfound .page-header{
    height:auto;
  }
  .notfound .page-header img{
    width:65%;
  }
}